<template>
    <h1 class="text-primary">{{ greeting }}</h1>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('user', ['currentUser']),
        greeting: function () {
            var message;
            const h = new Date().getHours();

            if (h < 12) {
                message = 'greetingMorning';
            } else if (h < 17) {
                message = 'greetingAfternoon';
            } else {
                message = 'greetingEvening';
            }

            return this.t(message, {name: this.currentUser.firstName});
        },
    },
};
</script>
