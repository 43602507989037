<template>
    <div v-if="src" class="image">
        <img v-bind="$props" :class="className" :src="src" @load="ready = true" v-show="ready" />
        <slot v-if="!ready" />
    </div>
</template>

<script>
export default {
    props: ['src', 'className'],
    data: () => ({
        ready: false,
    }),
    watch: {
        src() {
            this.ready = false;
        },
    },
};
</script>
